import {
  CapsuleEventFeedbackModel,
  SupervisorEmployeeFeedback,
} from "../models/dashboard/capsuleEventFeedbackModel";
import {
  NJEmployeeFeedback,
  NJEmployeeFeedbackModel,
  InputDetail,
  FeedbackQuestionId,
  MentalWellnessFeedback,
  FeedbackPromptResponse,
  TechversantFeedback,
  FeedbackPromptComments
} from "../models/dashboard/newJoinerFeedbackModel";
import {
  NJQEmployeeFeedback,
  NewJoinerFeedbacks,
} from "../models/dashboard/NJQEmployeeFeedback";
import {
  EmployeeFeedbackModel,
  FeedbackPromptResponseDetails
} from "../models/dashboard/EmployeeFeedbackModel";
import * as connectPromptModel from "../models/dashboard/connectPromptModel";
import * as TAWModel from "../models/dashboard/TAWModel";
import { ExternalApiService } from "../Services/External-API.service";
import { Constants } from "./constants";
//import { QuestionCircleIcon } from "@fluentui/react-northstar";
import { toast } from "react-toastify";
import { NewJoinerDOJModel } from "../models/dashboard/NewJoinerDOJModel";
import { EMFeedBackAPIModel } from "../models/dashboard/EMFeedBackAPIModel";
import { EMNJReminderModel } from "../models/dashboard/EMNJReminderModel";
import { NewJoinerDOJA45DFREMModel } from "../models/dashboard/NewJoinerDOJA45DFREMModel";
import { BuddyFeedbackDOJA45DModel } from "../models/dashboard/BuddyFeedbackDOJA45DModel";
import { EMNJACKA1DModel } from "../models/dashboard/EMNJACKA1DModel";
import { EMNJDOJA28DModel } from "../models/dashboard/EMNJDOJA28DModel";
import { EMNJDOJA28DREMINDERModel } from "../models/dashboard/EMNJDOJA28DREMINDERModel";
import { EMNJACKA6AND12D1Model } from "../models/dashboard/EMNJACKA6AND12D1Model";
import { logCustomError, logCustomInfo } from "../Services/TelemetryService";
import React from "react";
import { NJExpFeedBackModel} from "../models/dashboard/NJExpFeedBackModel";

var inputDetail = new InputDetail();
export const isNotIframe = (): boolean => {
  return window === window.parent && window === window.top;
};

export class Utilities {
  public static SaveMentalWellnessSurvey(
    data: any,
    user: string
  ): Promise<string> {
    // FETCH ADAPTIVE CARD DATA
    //Mental wellness survey -> On submission records get updated in DiPA database
    return (async () => {
      const feedbackval = new MentalWellnessFeedback();
      const peopleData = await ExternalApiService.fetchPeopleData(user);
      feedbackval.Comment = data["comment"];
      feedbackval.EnterpriseId = user;
      feedbackval.ScenarioId = String(data["scenarioid"]);
      feedbackval.CareerLevel = peopleData["jobCodeDescription"];
      if (data["PostAnonymously"] === "true") {
        feedbackval.IsPostAnonymous = true;
      } else {
        feedbackval.IsPostAnonymous = false;
      }
      feedbackval.FeedbackPromptResponseDetails = [];
      Object.keys(data).forEach((key) => {
        if (key !== Constants.action && key.split("_").length > 1) {
          let responseIds: string[] = [];
          responseIds = data[key] == null ? [] : data[key].split(",");
          responseIds.forEach((element) => {
            let question = new FeedbackPromptResponse();
            question.QuestionId = key.split("_")[0];
            question.OptionId = element;
            feedbackval.FeedbackPromptResponseDetails.push(question);
          });
        }
      });
      inputDetail.InputDetail = feedbackval;
      if (inputDetail.InputDetail.FeedbackPromptResponseDetails.length === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_SENDMENTALWELLNESSFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "MentalWellnessSurvey Feedback API Call success...",
            "Utility.cs -> SaveMentalWellnessSurvey"
          );
          return "success";
        } else {
          Utilities.LogError(
            "MentalWellnessSurvey Feedback API Call failed.",
            "Utility.cs -> SaveMentalWellnessSurvey"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveEmployeeFeedbackData(data: any, user: string): Promise<string> {
    //Employee feedback -> On submission records get updated in DiPA database
    return (async () => {
      const employeeFeedback = new EmployeeFeedbackModel();
      const peopleData = await ExternalApiService.fetchPeopleData(user);
      employeeFeedback.EnterpriseId = user;
      employeeFeedback.ScenarioId = String(data["scenarioid"]);
      employeeFeedback.Comment = data["comment"];
      employeeFeedback.CareerLevel = peopleData["jobCodeDescription"];
      if (data["PostAnonymously"] === "true") {
        employeeFeedback.IsPostAnonymous = true;
      } else {
        employeeFeedback.IsPostAnonymous = false;
      }
      employeeFeedback.FeedbackPromptResponseDetails = [];
      Object.keys(data).forEach((key) => {
        if (key !== Constants.action && key.split("_").length > 1) {
          let responseIds: string[] = [];
          responseIds = data[key] == null ? [] : data[key].split(",");
          responseIds.forEach((element) => {
            let question = new FeedbackPromptResponseDetails();
            question.QuestionId = key.split("_")[0];
            question.OptionId = element;
            employeeFeedback.FeedbackPromptResponseDetails.push(question);
          });
        }
      });

      inputDetail.InputDetail = employeeFeedback;
      if (inputDetail.InputDetail.FeedbackPromptResponseDetails.length === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (await ExternalApiService.saveEmployeeFeedback(inputDetail, process.env.REACT_APP_SENDEMPLOYEEFEEDBACK!)) {
          Utilities.LogInfo(
            "Employee Feedback API Call success...",
            "Utility.cs -> SaveEmployeeFeedbackData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "Employee Feedback API Call failed.",
            "Utility.cs -> SaveEmployeeFeedbackData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveNJQFeedbackData(data: any, user: string): Promise<Boolean> {
    // NJ Questionnaire -> On submission records get pushed to Strand.
    // FETCH ADAPTIVE CARD DATA
    return (async () => {
      const feedbackval = new NJEmployeeFeedbackModel();
      const employeeFeedback = new NJEmployeeFeedback();
      employeeFeedback.EnterpriseId = user;
      employeeFeedback.FeedbackDate = new Date().toISOString();
      employeeFeedback.CardUniqueId = data["CardUniqueId"];
      employeeFeedback.CommentTextFirst = data["textinputId1"];
      employeeFeedback.CommentTextSecond = data["textinput"];
      if (data["postanonymousID"] === "true") {
        employeeFeedback.IsPostAnonymous = true;
      } else {
        employeeFeedback.IsPostAnonymous = false;
      }
      employeeFeedback.ChannelType = Constants.msTeamsChannel;
      employeeFeedback.QuestionIds = [];
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackQuestionId();
        question.QuestionId = element;
        question.FeedbackValue = data[element];
        employeeFeedback.QuestionIds.push(question);
      });

      feedbackval.CategoryType = data["action"];
      feedbackval.EmployeeFeedback = employeeFeedback;
      inputDetail.InputDetail = feedbackval;

      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_SENDNJQSUPERVISORFEEDBACK!
        )
      ) {
        Utilities.LogInfo(
          "Supervisor Feedback API Call success...",
          "Utility.cs -> SaveNJQFeedbackData"
        );
        return true;
      } else {
        Utilities.LogError(
          "Supervisor Feedback API Call failed.",
          "Utility.cs -> SaveNJQFeedbackData"
        );
        return false;
      }
    })();
  }

  public static SaveCapsuleFeedbackData(
    data: any,
    user: string
  ): Promise<Boolean> {
    //CapsuleDUL -> On submission records get pushed to Strand.
    return (async () => {
      const capsule = new CapsuleEventFeedbackModel();
      capsule.CategoryType = Constants.categoryType;
      capsule.EmployeeFeedback = new SupervisorEmployeeFeedback();
      capsule.EmployeeFeedback.EmployeeMood = data[Constants.StrandChoiced];
      capsule.EmployeeFeedback.FeedbackText = data[Constants.StrandInputTextId];
      capsule.EmployeeFeedback.EnterpriseId = user;
      capsule.EmployeeFeedback.CardUniqueId = data[Constants.uid];
      capsule.EmployeeFeedback.FeedbackDate = new Date();
      capsule.EmployeeFeedback.NudgeCategory = Constants.nudgeCategory;
      capsule.EmployeeFeedback.ChannelType = Constants.msTeamsChannel;
      inputDetail.InputDetail = capsule;

      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_CAPSULESUPERVISORFEEDBACK!
        )
      ) {
        Utilities.LogInfo(
          "Capsule Nudge API Cosmos Call success.",
          "Utility.cs -> SaveCapsuleFeedbackData"
        );
        return true;
      } else {
        Utilities.LogInfo(
          "Capsule Nudge API Cosmos Call failed.",
          "Utility.cs -> SaveCapsuleFeedbackData"
        );
        return false;
      }
    })();
  }

  public static SaveNJQEmployeeFeedback(
    data: any,
    user: string
  ): Promise<string> {
    //New-Joiner feedback -> On submission records get inserted for different weeks and updated for same week in DiPA and Strand databases.
    return (async () => {
      var feedback = new NJQEmployeeFeedback();
      const peopleData = await ExternalApiService.fetchPeopleData(user);
      feedback.EnterpriseId = user;
      feedback.CareerLevel = peopleData["jobCodeDescription"];
      feedback.Comment = data["NewjoinerfeedbackCommentId"];
      feedback.ScenarioId = data["scenarioid"].split('_')[0];
      feedback.NewJoinerWeekPrompt = data["scenarioid"].split('_')[1];
      if (data["PostAnonymously"] === "true") {
        feedback.IsPostAnonymous = true;
      } else {
        feedback.IsPostAnonymous = false;
      }
      feedback.NewJoinerFeedbackDetails = [];
      Object.keys(data).forEach((key) => {
        if (key !== Constants.action && key.split("_").length > 1) {
          const employeefeedback = new NewJoinerFeedbacks();
          employeefeedback.QuestionId = key.split("_")[0];
          employeefeedback.OptionId = data[key].split("_")[0];
          feedback.NewJoinerFeedbackDetails.push(employeefeedback);
        }
      });

      inputDetail.InputDetail = feedback;
      if (inputDetail.InputDetail.NewJoinerFeedbackDetails.length === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_INSERTUPDATEFEEDBACKFORNEWJOINER!
          )
        ) {
          Utilities.LogInfo(
            "Employee Feedback API Call success.",
            "Utility.cs -> SaveNJQEmployeeFeedback"
          );
          return "success";
        } else {
          Utilities.LogInfo(
            "Employee Feedback API Call failed.",
            "Utility.cs -> SaveNJQEmployeeFeedback"
          );
          return "error";
        }
      }
    })();
  }
  public static SaveTechversantSurvey(
    data: any,
    user: string
  ): Promise<string> {
    // FETCH ADAPTIVE CARD DATA
    //Techversant journey survey -> On submission records get updated in DiPA database
    return (async () => {
      const feedbackval = new TechversantFeedback();
      const peopleData = await ExternalApiService.fetchPeopleData(user);
      feedbackval.EnterpriseId = user;
      feedbackval.ScenarioId = String(data["scenarioid"]);
      feedbackval.CareerLevel = peopleData["jobCodeDescription"];
      if (data["PostAnonymously"] === "true") {
        feedbackval.IsPostAnonymous = true;
      } else {
        feedbackval.IsPostAnonymous = false;
      }
      feedbackval.FeedbackPromptResponseDetails = [];
      feedbackval.FeedbackPromptComments = [];
      Object.keys(data).forEach((key) => {
        if (key !== Constants.action && key.split("_").length > 1) {
          let responseIds: string[] = [];
          responseIds = data[key] == null ? [] : data[key].split(",");
          responseIds.forEach((element) => {
            let question1 = new FeedbackPromptComments();
            question1.QuestionId = key.split("_")[0];
            question1.Comment = data[key];
            if ((question1.Comment[0].match(/\D+/))) {
              feedbackval.FeedbackPromptComments.push(question1);
            }
            let question = new FeedbackPromptResponse();
            question.QuestionId = key.split("_")[0];
            question.OptionId = element;
            if ((question.OptionId[0].match(/\d/g))) {
              feedbackval.FeedbackPromptResponseDetails.push(question);
            }
          });
        }
      });
      inputDetail.InputDetail = feedbackval;
      if (inputDetail.InputDetail.FeedbackPromptResponseDetails.length === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TECHVERSANTSURVEYFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TechversantJourneySurvey Feedback API Call success...",
            "Utility.cs -> TechversantJourneySurvey"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TechversantJourneySurvey Feedback API Call failed.",
            "Utility.cs -> TechversantJourneySurvey"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveConnectPromptData(
    data: any,
    user: string
  ): Promise<Boolean> {
    //Connect prompt -> On submission records get pushed to Strand
    return (async () => {
      const capsule = new connectPromptModel.ConnectPromptModel();
      capsule.EnterpriseId = user;
      capsule.CardUniqueId = data[Constants.CardUniqueId];
      capsule.CategoryType = Constants.supervisorsurveycp;
      capsule.ChannelType = Constants.webchat;
      if (data["postanonymousID"] === "true") {
        capsule.IsPostAnonymous = true;
      } else {
        capsule.IsPostAnonymous = false;
      }
      capsule.FeedbackPromptComments = [];
      capsule.FeedbackPromptResponseDetails = [];
      Object.keys(data).forEach((key) => {
        //to filter the keys like CPQ1_firstcomment for comments and like CPQ1(length=4) for choicesets
        if ((key !== Constants.action && key.split("_").length > 1) || key.length === 4) {
          let responseIds: string[] = [];
          responseIds = data[key] == null ? [] : data[key].split(",");
          responseIds.forEach(() => {
            let questionComment = new connectPromptModel.FeedbackPromptComments();
            questionComment.QuestionId = key.split("_")[0];
            questionComment.Comment = data[key]; //data[CPQ1_firstcomment]
            if (key.split("_")[0] !== key) {//checking if key is of comment and not of choiceset
              if (capsule.FeedbackPromptComments.length === 0) { //if first question then push without checking
                capsule.FeedbackPromptComments.push(questionComment);
              }
              //if not first question check if the question id of previous question does not matches this question id to avoid duplicating of elements
              else if (capsule.FeedbackPromptComments[capsule.FeedbackPromptComments.length - 1].QuestionId !== questionComment.QuestionId) {
                capsule.FeedbackPromptComments.push(questionComment);
              }
            }
            let question = new connectPromptModel.FeedbackPromptResponseDetails();
            question.QuestionId = key.split("_")[0];
            question.OptionId = data[question.QuestionId];
            if (question.OptionId != null || question.OptionId !== undefined) {//to not push question ids for questions without options
              // if (capsule.FeedbackPromptResponseDetails.length < Number(question.QuestionId.slice(-1)[0])) 
              if (capsule.FeedbackPromptResponseDetails.length === 0) { //if first question then push without checking
                capsule.FeedbackPromptResponseDetails.push(question);
              }
              //if not first question check if the question id of previous question does not matches this question id to avoid duplicating of elements
              else if (capsule.FeedbackPromptResponseDetails[capsule.FeedbackPromptResponseDetails.length - 1].QuestionId !== question.QuestionId) {
                capsule.FeedbackPromptResponseDetails.push(question);
              }
            }
          });
        }
      });
      inputDetail.InputDetail = capsule;

      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_CONNECTPROMPT!
        )
      ) {
        Utilities.LogInfo(
          "SaveConnectPromptData API Cosmos Call success.",
          "Utility.cs -> SaveConnectPromptData"
        );
        return true;
      } else {
        Utilities.LogInfo(
          "SaveConnectPromptData API Cosmos Call failed.",
          "Utility.cs -> SaveConnectPromptData"
        );
        return false;
      }
    })();
  }

  public static SaveTAWSHData(
    data: any,
    user: string
  ): Promise<Boolean> {
    //TAWSH Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawsh = new TAWModel.TAWSHModel();
      tawsh.EnterpriseId = user;
      tawsh.CardUniqueId = data[Constants.CardUniqueId];
      tawsh.CategoryType = Constants.surveytawsh;
      tawsh.ChannelType = Constants.Webchat;
      tawsh.IsPostAnonymous = false;
      tawsh.FeedbackPromptResponseDetails = [];
      tawsh.FeedbackPromptComments = [];

      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWSH/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          tawsh.FeedbackPromptResponseDetails.push(question);
        }
      });

      inputDetail.InputDetail = tawsh;
      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        )
      ) {
        Utilities.LogInfo(
          "TAWSH API Cosmos Call success.",
          "Utility.cs -> SaveTAWSHData"
        );
        return true;
      } else {
        Utilities.LogInfo(
          "TAWSH API Cosmos Call failed.",
          "Utility.cs -> SaveTAWSHData"
        );
        return false;
      }
    })();
  }

  public static SaveTAWSPHData(
    data: any,
    user: string
  ): Promise<Boolean> {
    //TAWSPH Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawsph = new TAWModel.TAWSPHModel();
      tawsph.EnterpriseId = user;
      tawsph.CardUniqueId = data[Constants.CardUniqueId];
      tawsph.CategoryType = Constants.surveytawsph;
      tawsph.ChannelType = Constants.Webchat;
      tawsph.IsPostAnonymous = false;
      tawsph.FeedbackPromptResponseDetails = [];
      tawsph.FeedbackPromptComments = [];

      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWSPH/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          tawsph.FeedbackPromptResponseDetails.push(question);
        }
      });

      inputDetail.InputDetail = tawsph;
      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        )
      ) {
        Utilities.LogInfo(
          "TAWSPH API Cosmos Call success.",
          "Utility.cs -> SaveTAWSPHData"
        );
        return true;
      } else {
        Utilities.LogInfo(
          "TAWSPH API Cosmos Call failed.",
          "Utility.cs -> SaveTAWSPHData"
        );
        return false;
      }
    })();
  }

  public static SaveTAWPWData(
    data: any,
    user: string
  ): Promise<Boolean> {
    //TAWPW Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawpw = new TAWModel.TAWPWModel();
      tawpw.EnterpriseId = user;
      tawpw.CardUniqueId = data[Constants.CardUniqueId];
      tawpw.CategoryType = Constants.surveytawpw;
      tawpw.ChannelType = Constants.Webchat;
      tawpw.IsPostAnonymous = false;
      tawpw.FeedbackPromptResponseDetails = [];
      tawpw.FeedbackPromptComments = [];

      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWPW/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          tawpw.FeedbackPromptResponseDetails.push(question);
        }
      });

      inputDetail.InputDetail = tawpw;
      if (
        await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        )
      ) {
        Utilities.LogInfo(
          "TAWPW API Cosmos Call success.",
          "Utility.cs -> SaveTAWPWData"
        );
        return true;
      } else {
        Utilities.LogInfo(
          "TAWPw API Cosmos Call failed.",
          "Utility.cs -> SaveTAWPWData"
        );
        return false;
      }
    })();
  }

  public static SaveTAWFSData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWFS Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawfs = new TAWModel.TAWFSModel();
      tawfs.EnterpriseId = user;
      tawfs.CardUniqueId = data[Constants.CardUniqueId];
      tawfs.CategoryType = Constants.surveytawfs;
      tawfs.ChannelType = Constants.Webchat;
      tawfs.IsPostAnonymous = false;
      tawfs.FeedbackPromptResponseDetails = [];
      tawfs.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWFS/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWFS1") {
            flag_num = 1
          }
          tawfs.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawfs;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWFS Feedback API Call success...",
            "Utility.cs -> SaveTAWFSData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWFS Feedback API Call failed.",
            "Utility.cs -> SaveTAWFSData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveTAWPTOData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWPTO Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawpto = new TAWModel.TAWPTOModel();
      tawpto.EnterpriseId = user;
      tawpto.CardUniqueId = data[Constants.CardUniqueId];
      tawpto.CategoryType = Constants.surveytawpto;
      tawpto.ChannelType = Constants.Webchat;
      tawpto.IsPostAnonymous = false;
      tawpto.FeedbackPromptResponseDetails = [];
      tawpto.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWPTO/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWPTO1") {
            flag_num = 1
          }
          tawpto.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawpto;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWPTO Feedback API Call success...",
            "Utility.cs -> SaveTAWPTOData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWPTO Feedback API Call failed.",
            "Utility.cs -> SaveTAWPTOData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveTAWPIOData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWPIO Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawpio = new TAWModel.TAWPIOModel();
      tawpio.EnterpriseId = user;
      tawpio.CardUniqueId = data[Constants.CardUniqueId];
      tawpio.CategoryType = Constants.surveytawpio;
      tawpio.ChannelType = Constants.Webchat;
      tawpio.IsPostAnonymous = false;
      tawpio.FeedbackPromptResponseDetails = [];
      tawpio.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWPIO/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWPIO1") {
            flag_num = 1
          }
          tawpio.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawpio;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWPIO Feedback API Call success...",
            "Utility.cs -> SaveTAWPIOData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWPIO Feedback API Call failed.",
            "Utility.cs -> SaveTAWPIOData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveTAWLHCData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWLHC Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawlhc = new TAWModel.TAWLHCModel();
      tawlhc.EnterpriseId = user;
      tawlhc.CardUniqueId = data[Constants.CardUniqueId];
      tawlhc.CategoryType = Constants.surveytawlhc;
      tawlhc.ChannelType = Constants.Webchat;
      tawlhc.IsPostAnonymous = false;
      tawlhc.FeedbackPromptResponseDetails = [];
      tawlhc.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWLHC/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWLHC1") {
            flag_num = 1
          }
          tawlhc.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawlhc;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWLHC Feedback API Call success...",
            "Utility.cs -> SaveTAWLHCData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWLHC Feedback API Call failed.",
            "Utility.cs -> SaveTAWLHCData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveTAWSWPData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWSWP Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawswp = new TAWModel.TAWSWPModel();
      tawswp.EnterpriseId = user;
      tawswp.CardUniqueId = data[Constants.CardUniqueId];
      tawswp.CategoryType = Constants.surveytawswp;
      tawswp.ChannelType = Constants.Webchat;
      tawswp.IsPostAnonymous = false;
      tawswp.FeedbackPromptResponseDetails = [];
      tawswp.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWSWP/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWSWP1") {
            flag_num = 1
          }
          tawswp.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawswp;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWSWP Feedback API Call success...",
            "Utility.cs -> SaveTAWSWPData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWSWP Feedback API Call failed.",
            "Utility.cs -> SaveTAWSWPData"
          );
          return "error";
        }
      }
    })();
  }

  public static SaveTAWQCData(
    data: any,
    user: string
  ): Promise<string> {
    //TAWQC Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const tawqc = new TAWModel.TAWQCModel();
      tawqc.EnterpriseId = user;
      tawqc.CardUniqueId = data[Constants.CardUniqueId];
      tawqc.CategoryType = Constants.surveytawqc;
      tawqc.ChannelType = Constants.Webchat;
      tawqc.IsPostAnonymous = false;
      tawqc.FeedbackPromptResponseDetails = [];
      tawqc.FeedbackPromptComments = [];
      let flag_num = 0;
      Object.keys(data).forEach((key) => {
        if (key.match(/^TAWQC/)) {
          let question = new FeedbackPromptResponse();
          question.QuestionId = key;
          question.OptionId = data[key];
          if (question.QuestionId === "TAWQC1") {
            flag_num = 1
          }
          tawqc.FeedbackPromptResponseDetails.push(question);
        }
      });
      inputDetail.InputDetail = tawqc;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "TAWQC Feedback API Call success...",
            "Utility.cs -> SaveTAWQCData"
          );
          return "success";
        } else {
          Utilities.LogError(
            "TAWQC Feedback API Call failed.",
            "Utility.cs -> SaveTAWQCData"
          );
          return "error";
        }
      }
    })();
  }
  public static SaveNewJoinerDOJ45Data(
    data: any,
    user: string
  ): Promise<string> {
    //NewJoinerDOJ Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NewJoinerDOJ = new NewJoinerDOJModel();
      NewJoinerDOJ.EnterpriseId = user;
      NewJoinerDOJ.CardUniqueId = data[Constants.CardUniqueId];
      NewJoinerDOJ.CategoryType = Constants.NewJoinerDOJ45;
      NewJoinerDOJ.ChannelType = Constants.webchat;
      NewJoinerDOJ.IsPostAnonymous = false;
      NewJoinerDOJ.FeedbackPromptResponseDetails = [];
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        NewJoinerDOJ.FeedbackPromptResponseDetails.push(question);

        if (question.OptionId != null) {
          flag_num = 1;
        }
      });


      inputDetail.InputDetail = NewJoinerDOJ;
      if (flag_num === 0) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "NewJoinerDOJ45 API Call success...",
            "Utility.cs -> SaveNewJoinerDOJ45Data"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "NewJoinerDOJ45 API Call failed.",
            "Utility.cs -> SaveNewJoinerDOJ45Data"
          );
          return 'error';
        }
      }
    })();
  }
  public static SaveEMFeedBackAPIData(
    data: any,
    user: string
  ): Promise<string> {
    //ExperienceManagerFeedBack Prompt -> On submission records get pushed to Strand.
    return (async () => {

      const EMFeedBackAPI = new EMFeedBackAPIModel();
      EMFeedBackAPI.EnterpriseId = user;
      EMFeedBackAPI.CardUniqueId = data[Constants.CardUniqueId];
      EMFeedBackAPI.CategoryType = Constants.EMNJDOJA15D;
      EMFeedBackAPI.ChannelType = Constants.webchat;
      EMFeedBackAPI.FeedbackPromptResponseDetails = [];
      EMFeedBackAPI.FeedbackPromptComments = [];
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        if (element !== 'EMNJ_DOJ_A15D_6') {
          let question = new FeedbackPromptResponseDetails();
          question.QuestionId = element;
          question.OptionId = data[element];
          EMFeedBackAPI.FeedbackPromptResponseDetails.push(question);

          if (question.OptionId != null) {
            flag_num = flag_num + 1;
          }
        }
        else {
          let question = new FeedbackPromptComments();
          question.QuestionId = element;
          question.Comment = data[element];
          EMFeedBackAPI.FeedbackPromptComments.push(question);

          if (question.Comment != null) {
            flag_num = flag_num + 1;
          }
        }
      });


      inputDetail.InputDetail = EMFeedBackAPI;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> SaveEMFeedBackAPIData"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> SaveEMFeedBackAPIData"
          );
          return 'error';
        }
      }
    })();
  }
  public static SaveEMNJReminderData(
    data: any,
    user: string
  ): Promise<string> {
    //EMNJReminder Prompt -> New Joiner should be able to receive a reminder nudge on DOJ+15 feedback nudge if he did not submit the feedback in first time -> On submission records get pushed to Strand.
    return (async () => {
      const NJoinerFeedback = new EMNJReminderModel();
      NJoinerFeedback.EnterpriseId = user;
      NJoinerFeedback.CardUniqueId = data[Constants.CardUniqueId];
      NJoinerFeedback.CategoryType = Constants.EMNJDOJA15DREMINDER;
      NJoinerFeedback.ChannelType = Constants.webchat;
      NJoinerFeedback.FeedbackPromptResponseDetails = [];
      NJoinerFeedback.FeedbackPromptComments = [];
      NJoinerFeedback.IsPostAnonymous = false;
      NJoinerFeedback.IsCPQFeedbackPrompt = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        if (element !== 'EMNJ_DOJ_A15D_REMINDER_6') {
          let question = new FeedbackPromptResponseDetails();
          question.QuestionId = element;
          question.OptionId = data[element];
          NJoinerFeedback.FeedbackPromptResponseDetails.push(question);

          if (question.OptionId != null) {
            flag_num = flag_num + 1;
          }
        }
        else {
          let question = new FeedbackPromptComments();
          question.QuestionId = element;
          question.Comment = data[element];
          NJoinerFeedback.FeedbackPromptComments.push(question);
          if (question.Comment != null) {
            flag_num = flag_num + 1;
          }
        }
      });
      inputDetail.InputDetail = NJoinerFeedback;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> NJoinerFeedbackData"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "NJoinerFeedback API Call failed.",
            "Utility.cs -> NJoinerFeedbackData"
          );
          return 'error';
        }
      }
    })();

  }
  public static SaveNewJoinerDOJA45DFREMResponse(
    data: any,
    user: string
  ): Promise<string> {
    //NewJoinerDOJA45DFREM and NewJoinerDOJA45DAFREM  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NewJoinerDOJA45DFREM = new NewJoinerDOJA45DFREMModel();
      NewJoinerDOJA45DFREM.EnterpriseId = user;
      NewJoinerDOJA45DFREM.CardUniqueId = data[Constants.CardUniqueId];
      NewJoinerDOJA45DFREM.CategoryType = data.action.split('_')[0];
      NewJoinerDOJA45DFREM.ChannelType = Constants.webchat;
      NewJoinerDOJA45DFREM.FeedbackPromptResponseDetails = [];
      NewJoinerDOJA45DFREM.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        NewJoinerDOJA45DFREM.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });

      inputDetail.InputDetail = NewJoinerDOJA45DFREM;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> SaveNewJoinerDOJA45DFREMModel"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> SaveNewJoinerDOJA45DFREMModel"
          );
          return 'error';
        }
      }
    })();
  }

  public static BuddyFeedbackDOJA45DResponse(
    data: any,
    user: string
  ): Promise<string> {
    //BuddyFeedbackDOJA45D  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const BuddyFeedbackDOJA45D = new BuddyFeedbackDOJA45DModel();
      BuddyFeedbackDOJA45D.EnterpriseId = user;
      BuddyFeedbackDOJA45D.CardUniqueId = data[Constants.CardUniqueId];
      BuddyFeedbackDOJA45D.FeedbackPromptComments = [];
      BuddyFeedbackDOJA45D.CategoryType = data.action.split('_')[0];
      BuddyFeedbackDOJA45D.ChannelType = Constants.webchat;
      BuddyFeedbackDOJA45D.FeedbackPromptResponseDetails = [];
      BuddyFeedbackDOJA45D.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        BuddyFeedbackDOJA45D.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });

      inputDetail.InputDetail = BuddyFeedbackDOJA45D;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> BuddyFeedbackDOJA45DModel"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> BuddyFeedbackDOJA45DMModel"
          );
          return 'error';
        }
      }
    })();
  }

  public static EMNJACKA1DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    //EMNJACKA1D  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const EMNJACKA1D = new EMNJACKA1DModel();
      EMNJACKA1D.EnterpriseId = user;
      EMNJACKA1D.CardUniqueId = data[Constants.CardUniqueId];
      EMNJACKA1D.FeedbackPromptComments = [];
      EMNJACKA1D.CategoryType = data.action.split('_')[0];
      EMNJACKA1D.ChannelType = Constants.webchat;
      EMNJACKA1D.FeedbackPromptResponseDetails = [];
      EMNJACKA1D.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        EMNJACKA1D.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });


      inputDetail.InputDetail = EMNJACKA1D;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> EMNJACKA1DModel"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> EMNJACKA1DModel"
          );
          return 'error';
        }
      }
    })();
  }
  public static EMNJDOJA28DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    //EMNJDOJA28D  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const EMNJDOJA28D = new EMNJDOJA28DModel();
      EMNJDOJA28D.EnterpriseId = user;
      EMNJDOJA28D.CardUniqueId = data[Constants.CardUniqueId];
      EMNJDOJA28D.FeedbackPromptComments = [];
      EMNJDOJA28D.CategoryType = data.action.split('_')[0];
      EMNJDOJA28D.ChannelType = Constants.webchat;
      EMNJDOJA28D.FeedbackPromptResponseDetails = [];
      EMNJDOJA28D.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        EMNJDOJA28D.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });
      inputDetail.InputDetail = EMNJDOJA28D;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> EMNJDOJA28DModel"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> EMNJDOJA28DModel"
          );
          return 'error';
        }
      }
    })();
  }
  public static EMNJDOJA28DREMINDERModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    //EMNJDOJA28DREMINDER  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const EMNJDOJA28DREMINDER = new EMNJDOJA28DREMINDERModel();
      EMNJDOJA28DREMINDER.EnterpriseId = user;
      EMNJDOJA28DREMINDER.CardUniqueId = data[Constants.CardUniqueId];
      EMNJDOJA28DREMINDER.FeedbackPromptComments = [];
      EMNJDOJA28DREMINDER.CategoryType = data.action.split('_')[0];
      EMNJDOJA28DREMINDER.ChannelType = Constants.webchat;
      EMNJDOJA28DREMINDER.FeedbackPromptResponseDetails = [];
      EMNJDOJA28DREMINDER.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        EMNJDOJA28DREMINDER.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });


      inputDetail.InputDetail = EMNJDOJA28DREMINDER;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> EMNJDOJA28DREMINDER"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> EMNJDOJA28DREMINDER"
          );
          return 'error';
        }
      }
    })();
  }

  public static EMNJACKA6AND12D1ModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    //EMNJACKA6AND12D1  Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const EMNJACKA1D = new EMNJACKA6AND12D1Model();
      EMNJACKA1D.EnterpriseId = user;
      EMNJACKA1D.CardUniqueId = data[Constants.CardUniqueId];
      EMNJACKA1D.FeedbackPromptComments = [];
      EMNJACKA1D.CategoryType = data.action.split('_')[0];
      EMNJACKA1D.ChannelType = Constants.webchat;
      EMNJACKA1D.FeedbackPromptResponseDetails = [];
      EMNJACKA1D.IsPostAnonymous = false;
      let flag_num = 0;
      let questionIds: string[] = [];
      questionIds =
        data["questionIds"] == null ? [] : data["questionIds"].split(",");
      questionIds.forEach((element) => {
        let question = new FeedbackPromptResponseDetails();
        question.QuestionId = element;
        question.OptionId = data[element];
        EMNJACKA1D.FeedbackPromptResponseDetails.push(question);
        if (question.OptionId != null) {
          flag_num = flag_num + 1;
        }
      });




      inputDetail.InputDetail = EMNJACKA1D;
      if (questionIds.length !== flag_num) {
        toast.error(
          <div className="custom-toast">
            <div>
              <div>Please answer all queries to</div>
              <div>submit the survey.</div>
            </div>
          </div>);
        return "doNotSubmit";
      }
      else {
        if (
          await ExternalApiService.saveFeedback(
            inputDetail,
            process.env.REACT_APP_TAWFEEDBACK!
          )
        ) {
          Utilities.LogInfo(
            "ExperienceManagerFeedBack  API Call success...",
            "Utility.cs -> EMNJACKA6AND12D1Model"
          );
          return 'success';
        } else {
          Utilities.LogError(
            "ExperienceManagerFeedBack  API Call failed.",
            "Utility.cs -> EMNJACKA6AND12D1Model"
          );
          return 'error';
        }
      }
    })();
  }

  //Nudge Cards Implementation

  public static NJExpPLDOJA1DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NJExpPLDOJA1D Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NJEXPPLDOJA1D = new NJExpFeedBackModel();
      NJEXPPLDOJA1D.EnterpriseId = user;
      NJEXPPLDOJA1D.CardUniqueId = data[Constants.CardUniqueId];
      NJEXPPLDOJA1D.FeedbackPromptComments = [];
      NJEXPPLDOJA1D.CategoryType = data.action.split('_')[0];
      NJEXPPLDOJA1D.ChannelType = Constants.webchat;
      NJEXPPLDOJA1D.FeedbackPromptResponseDetails = [];
      NJEXPPLDOJA1D.IsPostAnonymous = false;
      let validResponsesCount = 0;

      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NJEXPPLDOJA1D.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! Do connect with them and get them started on their
              Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success("Thank you for your response.");
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NJEXPPLDOJA1D"
        );
      }
      inputDetail.InputDetail = NJEXPPLDOJA1D;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NJExpPLDOJA1DFeedback API Call success...",
            "Utility.cs -> NJEXPPLDOJA1D"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          "NJExpPLDOJA1DFeedback API Call failed",
          "Utility.cs -> NJEXPPLDOJA1D"
        );
        return "error";
      }
    })();
  }

  public static NJHRPADOJPlus1ModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NJHRPADOJPlus1 Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NJHRPADOJPLUS1 = new NJExpFeedBackModel();
      NJHRPADOJPLUS1.EnterpriseId = user;
      NJHRPADOJPLUS1.CardUniqueId = data[Constants.CardUniqueId];
      NJHRPADOJPLUS1.FeedbackPromptComments = [];
      NJHRPADOJPLUS1.CategoryType = data.action.split('_')[0];
      NJHRPADOJPLUS1.ChannelType = Constants.webchat;
      NJHRPADOJPLUS1.FeedbackPromptResponseDetails = [];
      NJHRPADOJPLUS1.IsPostAnonymous = false;
      let validResponsesCount = 0;
      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NJHRPADOJPLUS1.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! Do connect with them and get them started on their
              Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success("Thank you for your response.");
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NJHRPADOJPLUS1"
        );
      }
      inputDetail.InputDetail = NJHRPADOJPLUS1;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NJHRPADOJPLUS1Feedback API Call success...",
            "Utility.cs -> NJHRPADOJPLUS1"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          "NJHRPADOJPLUS1Feedback API Call failed",
          "Utility.cs -> NJHRPADOJPLUS1"
        );
        return "error";
      }
    })();
  }

  public static NJExpPLA20DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NJExpPLA20D Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NJEXPPLA20D = new NJExpFeedBackModel();
      NJEXPPLA20D.EnterpriseId = user;
      NJEXPPLA20D.CardUniqueId = data[Constants.CardUniqueId];
      NJEXPPLA20D.FeedbackPromptComments = [];
      NJEXPPLA20D.CategoryType = data.action.split('_')[0];
      NJEXPPLA20D.ChannelType = Constants.webchat;
      NJEXPPLA20D.FeedbackPromptResponseDetails = [];
      NJEXPPLA20D.IsPostAnonymous = false;
      let validResponsesCount = 0;
      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NJEXPPLA20D.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! Do connect with them and get them started on their
              Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success("Thank you for your response.");
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NJEXPPLA20D"
        );
      }
      inputDetail.InputDetail = NJEXPPLA20D;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NJEXPPLA20DFeedback API Call success...",
            "Utility.cs ->  NJEXPPLA20D"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          " NJEXPPLA20DFeedback API Call failed",
          "Utility.cs ->  NJEXPPLA20D"
        );
        return "error";
      }
    })();
  }

  public static NJExpPLDOJA5DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NJExpPLDOJA5D Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NJEXPPLDOJA5D = new NJExpFeedBackModel();
      NJEXPPLDOJA5D.EnterpriseId = user;
      NJEXPPLDOJA5D.CardUniqueId = data[Constants.CardUniqueId];
      NJEXPPLDOJA5D.FeedbackPromptComments = [];
      NJEXPPLDOJA5D.CategoryType = data.action.split('_')[0];
      NJEXPPLDOJA5D.ChannelType = Constants.webchat;
      NJEXPPLDOJA5D.FeedbackPromptResponseDetails = [];
      NJEXPPLDOJA5D.IsPostAnonymous = false;
      let validResponsesCount = 0;
      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NJEXPPLDOJA5D.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! Do connect with them and get them started on their
              Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success("Thank you for your response.");
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NJEXPPLDOJA5D"
        );
      }
      inputDetail.InputDetail = NJEXPPLDOJA5D;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NJEXPPLDOJA5DFeedback API Call success...",
            "Utility.cs -> NJEXPPLDOJA5D"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          "NJEXPPLDOJA5DFeedback API Call failed",
          "Utility.cs -> NJEXPPLDOJA5D"
        );
        return "error";
      }
    })();
  }
  public static NJHRPADOJPlus5ModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NJHRPADOJPlus5 Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NJHRPADOJPlus5 = new NJExpFeedBackModel();
      NJHRPADOJPlus5.EnterpriseId = user;
      NJHRPADOJPlus5.CardUniqueId = data[Constants.CardUniqueId];
      NJHRPADOJPlus5.FeedbackPromptComments = [];
      NJHRPADOJPlus5.CategoryType = data.action.split('_')[0];
      NJHRPADOJPlus5.ChannelType = Constants.webchat;
      NJHRPADOJPlus5.FeedbackPromptResponseDetails = [];
      NJHRPADOJPlus5.IsPostAnonymous = false;
      let validResponsesCount = 0;

      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NJHRPADOJPlus5.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! Do connect with them and get them started on their
              Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success("Thank you for your response.");
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NJHRPADOJPlus5"
        );
      }
      inputDetail.InputDetail = NJHRPADOJPlus5;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NJHRPADOJPlus5ModelResponse API Call success...",
            "Utility.cs -> NJHRPADOJPlus5"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          "NJHRPADOJPlus5ModelResponse API Call failed",
          "Utility.cs -> NJHRPADOJPlus5"
        );
        return "error";
      }
    })();
  }

  public static NewJoinerExpA20DModelResponse(
    data: any,
    user: string
  ): Promise<string> {
    // NewJoinerExpA20D Prompt -> On submission records get pushed to Strand.
    return (async () => {
      const NewJoinerExpA20D = new NJExpFeedBackModel();
      NewJoinerExpA20D.EnterpriseId = user;
      NewJoinerExpA20D.CardUniqueId = data[Constants.CardUniqueId];
      NewJoinerExpA20D.FeedbackPromptComments = [];
      NewJoinerExpA20D.CategoryType = data.action.split('_')[0];
      NewJoinerExpA20D.ChannelType = Constants.webchat;
      NewJoinerExpA20D.FeedbackPromptResponseDetails = [];
      NewJoinerExpA20D.IsPostAnonymous = false;
      let validResponsesCount = 0;

      if (data) {
        const feedbackDetail = new FeedbackPromptResponseDetails();
        feedbackDetail.OptionId = data["questionId"];
        NewJoinerExpA20D.FeedbackPromptResponseDetails.push(feedbackDetail);
        validResponsesCount++;
        if (feedbackDetail.OptionId === "No") {
          toast.warn(
            <div style={{ color: "#222222" }}>
              Uh oh! If you are wondering, your HRPA and People Lead details are
              available on your Workday profile. Do connect with them and get
              started on your Accenture journey.
            </div>
          );
        }
        if (feedbackDetail.OptionId === "Yes") {
          toast.success(
            "Thank you for your response. Once again, welcome to Accenture!"
          );
        }
      } else {
        Utilities.LogError(
          `Invalid option selected   ${data["questionId"]}`,
          "Utility.cs -> NewJoinerExpA20D"
        );
      }
      inputDetail.InputDetail = NewJoinerExpA20D;
      try {
        const isSaved = await ExternalApiService.saveFeedback(
          inputDetail,
          process.env.REACT_APP_TAWFEEDBACK!
        );
        if (isSaved) {
          Utilities.LogInfo(
            "NewJoinerExpA20DModelResponse API Call success...",
            "Utility.cs -> NewJoinerExpA20D"
          );
          return "success";
        } else {
          throw new Error("API Call failed.");
        }
      } catch (error) {
        Utilities.LogError(
          "NewJoinerExpA20DModelResponse API Call failed",
          "Utility.cs -> NewJoinerExpA20D"
        );
        return "error";
      }
    })();
  }

public static NewJoinerExpA60DModelResponse(
  data: any,
  user: string
): Promise<string> {
  // NewJoinerExpA60D Prompt -> On submission records get pushed to Strand.
  return (async () => {
    const NewJoinerExpA60D = new NJExpFeedBackModel();
    NewJoinerExpA60D.EnterpriseId = user;
    NewJoinerExpA60D.CardUniqueId = data[Constants.CardUniqueId];
    NewJoinerExpA60D.FeedbackPromptComments = [];
    NewJoinerExpA60D.CategoryType = data.action.split('_')[0];
    NewJoinerExpA60D.ChannelType = Constants.webchat;
    NewJoinerExpA60D.FeedbackPromptResponseDetails = [];
    NewJoinerExpA60D.IsPostAnonymous = false;
    let validResponsesCount = 0;

    if (data) {
      const feedbackDetail = new FeedbackPromptResponseDetails();
      feedbackDetail.OptionId = data["questionId"];
      NewJoinerExpA60D.FeedbackPromptResponseDetails.push(feedbackDetail);
      validResponsesCount++;
    } else {
      Utilities.LogError(
        `Invalid option selected   ${data["questionId"]}`,
        "Utility.cs -> NewJoinerExpA60D"
      );
    }
    inputDetail.InputDetail = NewJoinerExpA60D;
    try {
      const isSaved = await ExternalApiService.saveFeedback(
        inputDetail,
        process.env.REACT_APP_TAWFEEDBACK!
      );
      if (isSaved) {
        Utilities.LogInfo(
          "NewJoinerExpA60DModelResponse API Call success...",
          "Utility.cs -> NewJoinerExpA60D"
        );
        return "success";
      } else {
        throw new Error("API Call failed.");
      }
    } catch (error) {
      Utilities.LogError(
        "NewJoinerExpA60DModelResponse API Call failed",
        "Utility.cs -> NewJoinerExpA60D"
      );
      return "error";
    }
  })();
}

  public static LogError(errMessage: string, methodName: string): void {
    if (process.env.REACT_APP_ISLOGENABLED === "true")
      logCustomError(errMessage, "Error in " + methodName + " at " + this.GetCurrentDateTime());
  }
  public static LogInfo(InfoMessage: string, methodName: string): void {
    if (process.env.REACT_APP_ISLOGENABLED === "true")
      logCustomInfo(InfoMessage, "Success in " + methodName + " at " + this.GetCurrentDateTime());
  }
  public static IsAIGUser(companyCode: string): boolean {
    let code: string = process.env.REACT_APP_AIGCOMPANYCODE!;
    if (code.includes(companyCode)) return true;
    else return false;
  }

  public static GetCurrentDateTime(): string {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    return dateTime;
  }
}
